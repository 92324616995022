import React from 'react';

const IconLoader = () => (
  <svg id='logo' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <title>Loader Logo</title>
    {/* AM text path */}
    <path d="M39.0361 59.6879H27.6421L25.5018 65.9575H18.7451L29.7824 35.4575H37.0846L48.038 65.9575H41.0295L39.0361 59.6879ZM37.2315 54.4321L33.3706 42.4307L29.3837 54.4321H37.2315ZM57.9002 65.9575H51.8779V35.4575H61.2785L66.902 59.4395L72.4837 35.4575H81.7797V65.9575H75.757V45.3276C75.757 44.7344 75.7641 43.9068 75.7781 42.8446C75.792 41.7686 75.799 40.9409 75.799 40.3616L69.9447 65.9575H63.6706L57.8582 40.3616C57.8582 40.9409 57.8652 41.7686 57.8792 42.8446C57.8932 43.9068 57.9002 44.7344 57.9002 45.3276V65.9575Z" fill="#64ffda"/>
    {/* Border icon path */}
    <path d="M50 5L11 27V72L50 95L89 73V28L50 5Z" stroke="#64ffda" strokeWidth="5"/>
  </svg>
);

export default IconLoader;
