import React from 'react';

const IconLogo = () => (
  <svg
    width="126"
    height="144"
    viewBox="0 0 126 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_23_64)">
      <path
        d="M63 4.5L4.5 37.5V105L63 139.5L121.5 106.5V39L63 4.5Z"
        fill="#0a192f"
        stroke="currentColor"
        strokeWidth="5"
      />
      <path
        d="M47.585 82.8628H33.0024L30.2632 91H21.6157L35.7417 51.415H45.0874L59.106 91H50.1362L47.585 82.8628ZM45.2754 76.0415L40.334 60.4653L35.2314 76.0415H45.2754ZM71.728 91H64.0205V51.415H76.0518L83.249 82.5405L90.3926 51.415H102.29V91H94.582V64.2251C94.582 63.4552 94.591 62.381 94.6089 61.0024C94.6268 59.606 94.6357 58.5317 94.6357 57.7798L87.1431 91H79.1133L71.6743 57.7798C71.6743 58.5317 71.6833 59.606 71.7012 61.0024C71.7191 62.381 71.728 63.4552 71.728 64.2251V91Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_23_64">
        <rect width="126" height="144" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLogo;
